//
//    Generated by trinidad-ui!
//
//
//    ██████╗  ██████╗     ███╗   ██╗ ██████╗ ████████╗    ███████╗██████╗ ██╗████████╗
//    ██╔══██╗██╔═══██╗    ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔════╝██╔══██╗██║╚══██╔══╝
//    ██║  ██║██║   ██║    ██╔██╗ ██║██║   ██║   ██║       █████╗  ██║  ██║██║   ██║
//    ██║  ██║██║   ██║    ██║╚██╗██║██║   ██║   ██║       ██╔══╝  ██║  ██║██║   ██║
//    ██████╔╝╚██████╔╝    ██║ ╚████║╚██████╔╝   ██║       ███████╗██████╔╝██║   ██║
//    ╚═════╝  ╚═════╝     ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚══════╝╚═════╝ ╚═╝   ╚═╝
//
//
//    All changes in this file will be overwritten next time
//    someone adds or generates new assets.
//
//    Read more on https://git.svenskaspel.se/trinidad/trinidad-ui ...
//
//    @author Team Design-tech
//
//    ---

const path = '/images/ui/bank/';
const cdn = global.window &&
  window.svs &&
  window.svs.core &&
  window.svs.core.config &&
  window.svs.core.config.data &&
  window.svs.core.config.data.svsconfig ?
window.svs.core.config.data.svsconfig.cdn :
'';

const svgs = {
  'apple-pay': 'apple-pay-69a2fca58efc0bdd93579d38154e79356fa08e6d.svg',
  bankgirot: 'bankgirot-453f9a0ad4d61a4d15b57635cc44d2d5ad4fc066.svg',
  'bankid-black': 'bankid-black-67d0ef76bc1e60fb1e5ffccbd1e6e1f0a92971ae.svg',
  'bankid-neg': 'bankid-neg-0152a170c229c0060b9d6f18a35ed743934b5cfd.svg',
  'bankid-promo': 'bankid-promo-5b472f46b2853880ac482b2a60cb0f4603d5f7fb.svg',
  bankid: 'bankid-5b472f46b2853880ac482b2a60cb0f4603d5f7fb.svg',
  'freja-eid-neg': 'freja-eid-neg-a71ebb46fffc13873af008b1ab15a66fcec2c790.svg',
  'freja-eid-vertical-neg': 'freja-eid-vertical-neg-8621080d880d3583fa263c04df90079930ee9e2e.svg',
  'freja-eid-vertical': 'freja-eid-vertical-627faba7b38ac2505bc2674433a0db5f6663daac.svg',
  'freja-eid': 'freja-eid-8991543084456b4d11197aa8583917cf8052c182.svg',
  handelsbanken: 'handelsbanken-637bbb41bfcf284355dc0874ea0dd4e2640c70ac.svg',
  mastercard: 'mastercard-206b38fe31b18d581025c679a92e34bf9f6348cd.svg',
  mastercardsecurecode: 'mastercardsecurecode-11874db9043ac7693059cf68b7fa31df8b21219a.svg',
  'masterpass-neg': 'masterpass-neg-36a38c20c2be4cfe86a4d4f5c4b9d99fb1df5322.svg',
  'masterpass-promo': 'masterpass-promo-59dba6098c662f22cb006a5f7744b3673644fc73.svg',
  masterpass: 'masterpass-79a7c948d0598ed14e4e4b8decd296494d54132b.svg',
  nordea: 'nordea-d7a64fd68d4b7bc7d090c80b28e8f5f47deb0b2e.svg',
  'payex-grey': 'payex-grey-1dd550b5ac4bd72b1e3a8b40afcf298a5b21a19c.svg',
  payex: 'payex-aaf8c01239c150455099bb737eedcef517a0838d.svg',
  seb: 'seb-b107377c9c88be81407a985dd57842a1a1855a8e.svg',
  'swedbank-sparbankerna': 'swedbank-sparbankerna-68c3fa1866b48576483765b70eb7caba4ab5ce35.svg',
  swedbank: 'swedbank-ac2c63aabd27229f528d1f29c5a6248b06a60634.svg',
  'swedbankpay-neg': 'swedbankpay-neg-c5539657ed69903807523dda9a6cda5149aae5a6.svg',
  swedbankpay: 'swedbankpay-de26e7c6f5bc076d70d52440018722fcf5c2ace7.svg',
  'swish-grey': 'swish-grey-5479a2023167637c8f0a1993404f6e7e2bd612de.svg',
  'swish-neg': 'swish-neg-3649251bbd46f7175294f019517d02c074c520f0.svg',
  'swish-promo': 'swish-promo-56d617769094b8a1db9a45db9502937b53074cf5.svg',
  swish: 'swish-0728bb5b20f22183d66ccca4941e460385ead0e4.svg',
  verifiedbyvisa: 'verifiedbyvisa-1501a9d6610b659fac712a85233af32aa44e6a68.svg',
  visa: 'visa-b174c36608e4d86de2a8670fe0d60e793a1c460d.svg'
};
const getGuard = (name) => {
  let match = name
    .trim()
    .replace(/[ÅÄåä]/g, 'a')
    .replace(/[Öö]/g, 'o')
    .replace(/Éé/g, 'e')
    .replace(/ /g, '-')
    .replace(/[^A-Za-z0-9_-]/g, '')
    .toLowerCase();

  if (svgs[match]) {
    match = svgs[match];
  }

  return match;
};

const getSvg = (name) => {
  const svg = getGuard(name);
  /* eslint-disable no-console */
  return svg ? svg : false;
};

const getAssetUrl = (svg) => cdn + path + svg;

export default {
  getAssetUrl,
  getSvg
};
